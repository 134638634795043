import { Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

const GetYear = ({ propsYear, selectYear }) => {
  const [year, setYear] = useState([]);
  useEffect(() => {
    const newYearArray = Array.from(
      { length: 12 },
      (_, index) => propsYear - 5 + index
    );
    setYear(newYearArray);
  }, [propsYear]);

  const clickYear = (clickedYear) => {
    selectYear(clickedYear);
  };
  return (
    <Paper elevation={3}>
      {year.map((row, index) => (
        <React.Fragment key={index}>
          {index % 3 === 0 && index !== 0 && <br />}
          <Button
            onClick={() => clickYear(row)}
            variant={row === propsYear ? "contained" : "text"}
          >
            {row}
          </Button>
        </React.Fragment>
      ))}
    </Paper>
  );
};
export default GetYear;
