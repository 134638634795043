import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../AppContext";
import {
  Box,
  Chip,
  Tabs,
  Tab,
  Typography,
  Modal,
  IconButton,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import "../Main/Table.css";
import { getAdminSpcRow, getFileInfo, setSpcSaveAdmin } from "../API/SpcAPI";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getAirList } from "../API/AirAPI";
import ViewFile from "../Util/ViewFile";
import {
  LabeledTextField,
  EmtButton,
  FilButton,
  CssTextField,
} from "../Util/ConvMui";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SquareIcon from "@mui/icons-material/Square";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  pr: 4,
  pl: 4,
  pb: 2,
  pt: 4,
  minHeight: "90vh",
  maxHegiht: "90vh",
  overflowX: "auto",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1, pl: 2 }}>{children}</Box>}
    </div>
  );
}

function CustomTableArray(props) {
  const { array, handleOpenFile } = props;
  return (
    <>
      {array &&
        array.map((row, index) => (
          <TableRow>
            <TableCell>{index + 1}</TableCell>
            <TableCell
              onClick={(e) => {
                if (row.agencyFileSid) {
                  e.stopPropagation();
                  handleOpenFile(row.agencyFileSid, row.fileExtension);
                }
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: "#46699C",
                  },
                }}
              >
                {row.fileName && row.fileName}
              </Box>
            </TableCell>
          </TableRow>
        ))}
    </>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DgPost({
  agencyGuid,
  handleRowClose,
  handleEmailAlert,
  handleEmailErrAlert,
  spcData,
  airList,
}) {
  const [carrierGuid, setCarrierGuid] = useState();
  const [agencyData, setAgencyData] = useState({});
  const [fileArr, setFileArr] = useState([]);
  const [value, setValue] = useState(0);
  const [spcNames, setSpcNames] = useState({});
  const { setErrFlag, setLoadFlag, setDgFlag } = useContext(AppContext);
  const [url, setUrl] = useState(null);
  const [remarkErr, setRemarkErr] = useState(false);

  useEffect(() => {
    if (spcData) {
      const tempSpc = spcData
        .filter((item) => fileArr.some((row) => row.spcType === item.sphType))
        .map((item) => item.sphType);
      const combSpc = [...new Set(tempSpc)];
      setSpcNames(combSpc);
    }
  }, [spcData, fileArr]);

  useEffect(() => {
    if (agencyData && agencyData.airSid) {
      setCarrierGuid(agencyData.airSid);
    }
  }, [agencyData.airSid]);

  useEffect(() => {
    if (agencyGuid) {
      getAdminSpcRow(agencyGuid, setErrFlag, setLoadFlag)
        .then(
          (result) =>
            result &&
            (setAgencyData(result[0].mainEntities),
            setFileArr(result[0].subEntities))
        )
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  }, [agencyGuid]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setUrl(null);
  };
  const handleOpenFile = (fileGuid, fileExtension) => {
    getFileInfo(fileGuid, setErrFlag, setLoadFlag)
      .then((result) => result && setUrl({ type: fileExtension, data: result }))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const handleRemarkChange = (newValue) => {
    setAgencyData((prevAgencyData) => ({
      ...prevAgencyData,
      agencyRemark: newValue,
    }));
  };
  const handleConfirmClick = () => {
    const data = {
      spcSid: agencyData.agencySid,
      approveFlag: "Y",
      remark: "",
    };
    setSpcSaveAdmin(data, setErrFlag, setLoadFlag)
      .then((result) => result && (handleRowClose(), setDgFlag(true)))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));

    agencyData.ReadFlag = "Y";
  };

  const handleRejectClick = () => {
    const data = {
      spcSid: agencyData.agencySid,
      approveFlag: "N",
      remark: agencyData.agencyRemark,
    };

    if (agencyData.agencyRemark === "" || agencyData.agencyRemark === null) {
      setRemarkErr(true);
    } else {
      setSpcSaveAdmin(data, setErrFlag, setLoadFlag)
        .then((result) =>
          result === "EMAIL" || result === true
            ? (handleEmailAlert(), handleRowClose(), setDgFlag(true))
            : (handleEmailErrAlert(), handleRowClose(), setDgFlag(true))
        )
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));

      agencyData.ReadFlag = "Y";
    }
  };

  return (
    <Box sx={style}>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#00114FDD",
          display: "flex",
          alignItems: "center",
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <ArrowRightIcon sx={{ color: "#fff" }} fontSize="large" />
        <Typography
          sx={{
            fontSize: 18,
            fontFamily: "Noto Sans KR",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          세부 정보
        </Typography>
        <IconButton sx={{ ml: "auto" }} onClick={handleRowClose}>
          <CloseIcon sx={{ color: "#fff" }} />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Box
          sx={{
            width: 300,
            margin: 1,
          }}
        >
          <table className="inputTableInquery1">
            <tr>
              <LabeledTextField
                value={agencyData.masterAirWayBillNo?.replace(
                  /^(\d{3})(\d{4})(\d+)$/,
                  `$1-$2-$3`
                )}
                startAdornmentText={"MAWB"}
                endAdornmentProps={{ sx: { visibility: "hidden" } }}
                inputProps={{ readOnly: true }}
                inBorder={"2px solid #6F7E8C"}
              ></LabeledTextField>
            </tr>
          </table>
        </Box>
        {agencyData?.agencyFlag === "W" && (
          <Chip
            sx={{ width: 90, fontFamily: "Noto Sans KR", mr: 1 }}
            label="Awaiting"
            color="primary"
            variant="outlined"
          />
        )}
        {agencyData?.agencyFlag === "Y" && (
          <Chip
            sx={{ width: 90, fontFamily: "Noto Sans KR", mr: 1 }}
            label="Confirmed"
            color="success"
          />
        )}
        {agencyData?.agencyFlag === "N" && (
          <Chip
            sx={{ width: 90, fontFamily: "Noto Sans KR", mr: 1 }}
            label="Rejected"
            color="warning"
          />
        )}
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 300,
            margin: 1,
          }}
        >
          <Box sx={{ mb: 1.5 }}>
            <LabeledTextField
              value={
                airList &&
                airList?.find((item) => item.guid === agencyData?.airSid)
                  ?.value4
              }
              startAdornmentText={"터미널"}
              endAdornmentProps={{ sx: { visibility: "hidden" } }}
              inputProps={{ readOnly: true }}
            ></LabeledTextField>
          </Box>
          <Box>
            <LabeledTextField
              value={agencyData?.agencyClientName}
              startAdornmentText={"대리점"}
              endAdornmentProps={{ sx: { visibility: "hidden" } }}
              inputProps={{ readOnly: true }}
            ></LabeledTextField>
          </Box>
        </Box>
        <Box
          sx={{
            width: 300,
            margin: 1,
          }}
        >
          <Box sx={{ mb: 1.5 }}>
            <LabeledTextField
              value={
                airList &&
                airList?.find((item) => item.guid === agencyData?.airSid)
                  ?.value2
              }
              startAdornmentText={"항공사"}
              endAdornmentProps={{ sx: { visibility: "hidden" } }}
              inputProps={{ readOnly: true }}
            ></LabeledTextField>
          </Box>
          <Box>
            <LabeledTextField
              value={agencyData.agencyPhone?.replace(
                /^(\d{2,3})(\d{3,4})(\d{4})$/,
                `$1-$2-$3`
              )}
              startAdornmentText={"연락처"}
              endAdornmentProps={{ sx: { visibility: "hidden" } }}
              inputProps={{ readOnly: true }}
            ></LabeledTextField>
          </Box>
        </Box>
        <Box
          sx={{
            width: 350,
            margin: 1,
          }}
        >
          <Box sx={{ mb: 1.5 }}>
            <LabeledTextField
              value={agencyData.agencyDate?.replace(
                /^(\d{4})(\d{2})(\d{2})$/,
                `$1-$2-$3`
              )}
              startAdornmentText={"날짜"}
              endAdornmentProps={{ sx: { visibility: "hidden" } }}
              inputProps={{ readOnly: true }}
            ></LabeledTextField>
          </Box>
          <Box>
            <LabeledTextField
              value={agencyData?.agencyEmail}
              startAdornmentText={"이메일"}
              endAdornmentProps={{ sx: { visibility: "hidden" } }}
              inputProps={{ readOnly: true }}
            ></LabeledTextField>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ mt: 2, borderBottom: 1, borderColor: "divider", ml: 2, mr: 1 }}
      >
        <Tabs value={value} onChange={handleTabChange}>
          {Array.isArray(spcNames) &&
            spcNames.map((item, index) => (
              <Tab
                sx={{
                  fontFamily: "Noto Sans KR",
                }}
                label={item}
                {...a11yProps(index)}
              />
            ))}
        </Tabs>
      </Box>
      <Box>
        {Array.isArray(spcNames) &&
          spcNames.map((item, index) => {
            return (
              <CustomTabPanel value={value} index={index}>
                <TableContainer
                  sx={{
                    borderRadius: 1,
                    width: "60%",
                    maxHeight: "40vh",
                    minHeight: "40vh",
                  }}
                >
                  <Table className="file-table">
                    <TableHead sx={{ backgroundColor: "#063B6D22" }}>
                      <TableRow>
                        <TableCell sx={{ width: "15%" }}>No</TableCell>
                        <TableCell>파일명</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <CustomTableArray
                        array={fileArr.filter((row) => item === row.spcType)}
                        handleOpenFile={handleOpenFile}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </CustomTabPanel>
            );
          })}
      </Box>

      <Box
        sx={{
          pr: 3,
          pl: 3,
          mt: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SquareIcon sx={{ fontSize: 10, mb: 0.6, mr: 1 }} />
          <Typography
            sx={{ fontFamily: "Noto Sans KR", fontWeight: "bold", mb: 1 }}
          >
            REJECT
          </Typography>
        </Box>
        <CssTextField
          multiline
          rows={2}
          placeholder="Reject 시 사유를 입력해 주세요."
          sx={{ width: "100%" }}
          value={agencyData.agencyRemark || ""}
          onChange={(e) => {
            handleRemarkChange(e.target.value);
          }}
          error={remarkErr}
        ></CssTextField>
      </Box>
      <Box sx={{ textAlign: "right", mt: 2, pr: 3 }}>
        <FilButton
          variant="contained"
          sx={{ mr: 1 }}
          onClick={handleConfirmClick}
        >
          Confirm
        </FilButton>
        <EmtButton variant="outlined" onClick={handleRejectClick}>
          Reject
        </EmtButton>
      </Box>
      {url && (
        <Modal open={url ? true : false} onClose={handleClose}>
          <ViewFile url={url} handleClose={handleClose} />
        </Modal>
      )}
    </Box>
  );
}
