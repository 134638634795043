import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_API_AUTHDOMAIL,
  projectId: process.env.REACT_APP_API_ID,
  storageBucket: process.env.REACT_APP_API_STORAGE,
  messagingSenderId: process.env.REACT_APP_API_SENDERID,
  appId: process.env.REACT_APP_API_APIID,
  measurementId: process.env.REACT_APP_API_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = async () => {
  console.log("권한 요청 중...");
  if (Notification.permission === "granted") {
    console.log("알림 권한이 허용됨");
    return await getToken(messaging, {
      vapidKey: process.env.REACT_APP_API_VAPIDKEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          sessionStorage.setItem("token", currentToken);
        } else {
          console.log("tokenError");
        }
      })
      .catch((err) => {
        console.log("token catch" + err);
      });
  } else {
    console.log("알림 권한 허용 안됨");
  }
};

requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
