const apiUrl = process.env.REACT_APP_API_URL;
export const getDetailList = async (intraGuid, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(
      `${apiUrl}/intra/getDetailList?intraGuid=${intraGuid}`
    );
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          detailGuid: row.detailSid,
          intraGuid: row.intraSid,
          detailContents: row.detailContents,
          detailRemark: row.detailRemark,
          detailStartTIme: row.detailStartTIme,
          detailEndTime: row.detailEndTime,
          detailTag: "",
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
export const detailRowDel = async (detailGuid, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(
      `${apiUrl}/intra/setDetailRowDel?detailGuid=${detailGuid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 필요한 경우 인증 토큰 등의 헤더를 여기에 추가할 수 있습니다.
        },
      }
    );
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
};

export const detailSave = async (row, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(`${apiUrl}/intra/setDetailSave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 필요한 경우 인증 토큰 등의 헤더를 여기에 추가할 수 있습니다.
      },
      body: JSON.stringify({
        detailSid: row[0].detailGuid,
        intraSid: row[0].intraGuid,
        detailContents: row[0].detailContents,
        detailStartTIme: row[0].detailStartTIme,
        detailEndTime: row[0].detailEndTime,
        detailRemark: row[0].detailRemark,
        sendUserSid: row[0].sendUserSid,
        userSid: row[0].userSid,
      }),
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
export const setDetailAddRow = async (
  intraGuid,
  userGuid,
  setErrFlag,
  setLoadFlag
) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(
      `${apiUrl}/intra/setDetailRowAdd?intraGuid=${intraGuid}&userGuid=${userGuid}`
    );
    if (!response.ok) {
      throw new Error(`${response.statusText}`);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error });
  }
  setLoadFlag(false);
  return false;
};
