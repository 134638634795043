import { setLogLevel } from "@firebase/app";
import { async } from "@firebase/util";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_CARGOURL;

export const getMawbInfoAdmin = async (
  date,
  userGuid,
  terminal,
  setErrFlag,
  setLoadFlag
) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(
      `${apiUrl}/spc/infoAdmin?date=${date}&userGuid=${userGuid}&terminal=${terminal}`,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      if (response.status === 400) {
        const errorText = await response.json();
        throw new Error(errorText.errCode);
      }
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag !== "N") {
        throw new Error(res.data[0]);
      } else {
        if (res.data) {
          const dataArray = res.data.map((row, index) => ({
            id: index,
            mainEntities: row.mainEntities,
            codeList: row.codeList,
          }));
          setLoadFlag(false);
          return dataArray;
        } else {
          setLoadFlag(false);
          return [];
        }
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "DG조회 ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getFileInfo = async (fileGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await axios.get(
      `${apiUrl}/spc/readFile?fileGuid=${fileGuid}`,
      {
        responseType: "arraybuffer",
      }
    );

    const contentType = response.headers["content-type"];
    const blob = new Blob([response.data], { type: contentType });
    const url = URL.createObjectURL(blob);
    setLoadFlag(false);
    return url;
  } catch (error) {
    setErrFlag({ code: "Y", data: "위험물등록ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getAdminSpcRow = async (agencyGuid, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(
      `${apiUrl}/spc/rowAdmin?agencyGuid=${agencyGuid}`,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      if (response.status === 400) {
        const errorText = await response.json();
        throw new Error(errorText.errCode);
      }
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag !== "N") {
        throw new Error(res.data[0]);
      } else {
        if (res.data) {
          const dataArray = res.data.map((row, index) => ({
            id: index,
            mainEntities: row.mainEntities,
            subEntities: row.subEntities,
          }));
          setLoadFlag(false);
          return dataArray;
        } else {
          setLoadFlag(false);
          return [];
        }
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "DG조회 ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setSpcSaveAdmin = async (data, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(`${apiUrl}/spc/saveAdmin`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      if (response.status === 400) {
        const errorText = await response.json();
        throw new Error(errorText.errCode);
      }
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag !== "N") {
        throw new Error(res.data);
      } else {
        if (res.errCode === "EMAIL") {
          setLoadFlag(false);
          return res.errCode;
        } else {
          setLoadFlag(false);
          return true;
        }
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "DG조회 ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getSpcEmailList = async (agencyGuid, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(
      `${apiUrl}/spc/spcEmailLog?agencyGuid=${agencyGuid}`,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      if (response.status === 400) {
        const errorText = await response.json();
        throw new Error(errorText.errCode);
      }
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag !== "N") {
        throw new Error(res.data[0]);
      } else {
        if (res.data) {
          const dataArray = res.data;
          setLoadFlag(false);
          return dataArray;
        }
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "DG조회 ERROR : " + error.message });
  }
  setLoadFlag(false);
  return false;
};
