import { requestPermission } from "../firebase";
const apiUrl = process.env.REACT_APP_API_URL;
export const loginSelect = async (data, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(`${apiUrl}/user/sign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "정보없음" });
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        sessionStorage.setItem("jwt", res.data[0].jwtToken);
        sessionStorage.setItem("userSid", res.data[0].userSid);
        sessionStorage.setItem("path", res.data[0].startPath);

        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "서버에러" + error.message });
  }
  setLoadFlag(false);
};

export const assignSelect = async (data, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(`${apiUrl}/user/asign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();

    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "저장안됨" });
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: "서버에러" + res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: "서버에러" + error.message });
  }
  setLoadFlag(false);
};
