import {
  Box,
  Modal,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import React, { useEffect, useState } from "react";
import "./Table.css";
import CplRowModal from "../Chart/CplRowModal";
import moment from "moment";
import { AppContext } from "../AppContext";
import { useContext } from "react";

const CplPublicTable = ({ viewYearMonth, dataArray }) => {
  const { monValue } = useContext(AppContext);
  const [personalData, setPersonalData] = useState([]);
  const [openRowModal, setOpenRowModal] = useState(false);
  const [editingRow, setEditingRow] = React.useState(null);
  const [editedValues, setEditedValues] = React.useState({});
  const [editIndex, setEditIndex] = useState();

  const handleRowClose = () => setOpenRowModal(false);

  const selectRow = (index) => {
    setEditingRow(null);
    const data = personalData[index].intraGuid;
    setEditIndex(data);
    setOpenRowModal(true);
  };

  useEffect(() => {
    const data = dataArray.filter(
      (item) => item.dataStatus === "A" && item.intraCompleteFlag === "Y"
    );
    setPersonalData(data);
  }, [dataArray]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Paper sx={{ mt: 5, pr: 2, pl: 2, pb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
            mb: 2,
            ml: 2,
          }}
        >
          <Typography className="menuTitle">그룹</Typography>
        </Box>
        <TableContainer
          sx={{ borderRadius: 1, width: "90vw", maxHeight: "60vh" }}
        >
          <Table className="cpl-table">
            <TableHead sx={{ backgroundColor: "#C7DBDC" }}>
              <TableRow>
                <TableCell sx={{ width: "3%" }}>중요도</TableCell>
                <TableCell sx={{ width: "3%" }}>No</TableCell>
                <TableCell sx={{ width: "16%" }}>일정명</TableCell>
                <TableCell sx={{ width: "5%" }}>시작시간</TableCell>
                <TableCell sx={{ width: "5%" }}>종료시간</TableCell>
                <TableCell sx={{ width: "10%" }}>요청부서</TableCell>
                <TableCell sx={{ width: "16%" }}>세부일정명</TableCell>
                {Array.from(
                  { length: viewYearMonth },
                  (_, index) => index + 1
                ).map((day) => (
                  <TableCell key={day} sx={{ fontSize: 11, width: "1.2%" }}>
                    {day}
                  </TableCell>
                ))}
                {editingRow !== null && <TableCell>완료</TableCell>}
                <TableCell>작성자</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!(personalData.length === 0) &&
                personalData.map((row, index) => (
                  <TableRow
                    hover
                    key={index}
                    onClick={(event) => (
                      event.stopPropagation(),
                      personalData.length !== 0 && selectRow(index)
                    )}
                  >
                    <TableCell>
                      {row.intraImportantFlag === "Y" ? (
                        <StarIcon sx={{ color: "#FF7070" }}></StarIcon>
                      ) : null}
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {row.intraSubject && row.intraSubject}
                    </TableCell>
                    <TableCell>
                      {row.intraStartTime &&
                        moment(row.intraStartTime).format("MM-DD HH:mm")}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {row.intraEndTime &&
                        moment(row.intraEndTime).format("MM-DD HH:mm")}
                    </TableCell>
                    <TableCell>{row.intraDeptStr}</TableCell>

                    <TableCell>
                      {row.dateData.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 0,
                            borderBottom: "none", // 필요에 따라 테이블 셀 경계를 조정
                          }}
                        >
                          <TableCell
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 0,
                              borderBottom: "none", // 필요에 따라 테이블 셀 경계를 조정
                              height: "25px", // 임시 고정 값
                            }}
                          >
                            {row.contents !== null ? row.contents : "\u00A0"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableCell>
                    {Array.from(
                      { length: viewYearMonth },
                      (_, index) => index + 1
                    ).map((day) => (
                      <TableCell sx={{ padding: 0 }} key={day}>
                        {row.dateData.map((item, itemIndex) => (
                          <TableRow
                            sx={{
                              padding: 0,
                              display: "contents",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            key={item}
                          >
                            <Box
                              sx={{
                                height: "23px",
                                mt: "2px",
                                backgroundColor: (() => {
                                  let color = "none";

                                  const itemSubArray = item.dateValue;
                                  if (itemSubArray) {
                                    for (
                                      let i = 0;
                                      i < itemSubArray.length;
                                      i++
                                    ) {
                                      if (
                                        itemSubArray[i].day === day &&
                                        monValue === itemSubArray[i].mon
                                      ) {
                                        switch (itemIndex % 5) {
                                          case 0:
                                            color = "#264653";
                                            break;
                                          case 1:
                                            color = "#2a9d8f";
                                            break;
                                          case 2:
                                            color = "#e9c46a";
                                            break;
                                          case 3:
                                            color = "#f4a261";
                                            break;
                                          case 4:
                                            color = "#e76f51";
                                            break;
                                          default:
                                            color = "none";
                                            break;
                                        }
                                        break;
                                      }
                                    }
                                  }
                                  return color;
                                })(),
                              }}
                            >
                              &nbsp;
                            </Box>
                          </TableRow>
                        ))}
                      </TableCell>
                    ))}
                    <TableCell>{row.userName}</TableCell>
                  </TableRow>
                ))}
              {/* {Array.from({ length: tableRow }, (_, index) => (
                <TableRow
                  hover
                  key={index}
                  onClick={() => editingRow == null && handleRowOpen()}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {editingRow === index ? (
                      <TextField
                        size="small"
                        value={editedValues[index]}
                        onChange={(e) => handleChange(e, 0)}
                      />
                    ) : (
                      editedValues[index]
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {Array.from(
                    { length: viewYearMonth },
                    (_, index) => index + 1
                  ).map((day) => (
                    <TableCell key={day}></TableCell>
                  ))}
                </TableRow>
              ))} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Modal
        open={openRowModal}
        onClose={handleRowClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <CplRowModal intraGuid={editIndex} />
        </div>
      </Modal>
    </Box>
  );
};

export default CplPublicTable;
