import React from "react";
import AlertMain from "../Alert/AlertMain";
import {
  Button,
  Box,
  Paper,
  Checkbox,
  IconButton,
  Input,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getMenuListAll, setMenuSave, setMenuDelete } from "../API/MenuAPI";
import { useEffect } from "react";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import "../Main/Table.css";

const SaveButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#729F76",
  "&:hover": {
    backgroundColor: "#567359",
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: "#729F76",
  borderColor: "#729F76",
  "&:hover": {
    borderColor: "#729F76",
    backgroundColor: "#E5F4E6",
  },
}));

export default function AdminMenu() {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    getMenuListAll(setErrFlag, setLoadFlag)
      .then((result) => result && (setData(result), setOriginData(result)))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, [flag]);

  const addClick = () => {
    setData([
      ...data,
      {
        id: data.length,
        menuSid: null,
        sortNum: 0,
        menuPath: "",
        menuName: "",
        usableFlag: "Y",
        changeFlag: "Y",
      },
    ]);
  };

  const changeData = (value, col, colIndex) => {
    setData((prevData) =>
      prevData.map((item, index) =>
        index === colIndex ? { ...item, [col]: value, changeFlag: "Y" } : item
      )
    );
  };

  const menuSave = () => {
    const saveData = data.filter((item) => item.changeFlag === "Y");

    setMenuSave(saveData, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };
  const menuCancel = () => {
    setData(originData);
  };

  const menuDelete = (menuGuid) => {
    setMenuDelete(menuGuid, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <div style={{ height: 110 }}>
        <AlertMain />
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Paper
          sx={{
            pr: 2,
            pl: 2,
            ml: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
              mb: 2,
              ml: 2,
            }}
          >
            <Typography className="menuTitle">메뉴 관리</Typography>
            <Box sx={{ mr: 1 }}>
              <SaveButton variant="contained" sx={{ mr: 1 }} onClick={menuSave}>
                저장
              </SaveButton>
              <CancelButton variant="outlined" onClick={menuCancel}>
                취소
              </CancelButton>
            </Box>
          </Box>
          <TableContainer
            sx={{
              borderRadius: 1,
              width: "50vw",
              maxHeight: "70vh",
              overflowX: "auto",
            }}
          >
            <Table className="admin-table">
              <TableHead sx={{ backgroundColor: "#d9e5d6" }}>
                <TableRow>
                  <TableCell sx={{ width: "3%" }}></TableCell>
                  <TableCell>메뉴명</TableCell>
                  <TableCell>순서</TableCell>
                  <TableCell>경로</TableCell>
                  <TableCell>삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: 0 }}>{index + 1}</TableCell>
                      <TableCell sx={{ padding: 0 }}>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.menuName}
                          onChange={(e) => {
                            changeData(e.target.value, "menuName", index);
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell sx={{ padding: 0 }}>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.sortNum}
                          onChange={(e) => {
                            changeData(e.target.value, "sortNum", index);
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell sx={{ padding: 0 }}>
                        {" "}
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.menuPath}
                          onChange={(e) => {
                            changeData(e.target.value, "menuPath", index);
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      {row.menuSid ? (
                        <TableCell sx={{ padding: 0 }}>
                          <IconButton
                            onClick={(event) => (
                              event.stopPropagation(), menuDelete(row.menuSid)
                            )}
                          >
                            <DeleteIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ textAlign: "right" }}>
            <IconButton onClick={addClick} sx={{ mt: 1 }}>
              <AddCircleIcon sx={{ fontSize: 48, color: "#729F76" }} />
            </IconButton>
          </Box>
        </Paper>
      </Box>
    </div>
  );
}
