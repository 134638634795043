import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import { getDetailList } from "../API/ChartAPI";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

export default function CplRowModal({ intraGuid, rowBool }) {
  const [rows, setRows] = React.useState([]);
  const { setErrFlag, setLoadFlag } = useContext(AppContext);

  React.useEffect(() => {
    if (intraGuid) {
      getDetailList(intraGuid, setErrFlag, setLoadFlag)
        .then((result) => (result === false ? setRows([]) : setRows(result)))
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  }, [intraGuid, rowBool]);

  return (
    <Box sx={style}>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "70vh", overflowX: "auto" }}
      >
        <Table className="basicTable">
          <TableHead sx={{ backgroundColor: "#cfe0c3" }}>
            <TableRow>
              <TableCell sx={{ width: 50 }}>No</TableCell>
              <TableCell sx={{ width: 300 }}>내용</TableCell>
              <TableCell sx={{ width: 300 }}>시작시간</TableCell>
              <TableCell sx={{ width: 300 }}>종료시간</TableCell>
              <TableCell sx={{ width: 400 }}>Remark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.detailContents}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="center">{row.detailContents}</TableCell>
                <TableCell align="center">
                  {moment(row.detailStartTIme).format("MM-DD HH:mm")}
                </TableCell>
                <TableCell align="center">
                  {row.detailEndTime
                    ? moment(row.detailEndTime).format("MM-DD HH:mm")
                    : null}
                </TableCell>
                <TableCell align="center">{row.detailRemark}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
