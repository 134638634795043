import React from "react";
import { useState, useRef, useEffect } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Input,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  TextField,
  Button,
  Chip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { getCommonList } from "../API/CommonAPI";
import "../Main/Table.css";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

export default function TableComboSec({
  tableList,
  value,
  changeData,
  type,
  deleteData,
  showValue,
  width,
}) {
  const [tableClicked, setTableClicked] = useState(false);
  const [selectedCode, setSelectedCode] = useState([]);
  const [col, setCol] = useState({});
  const comboTableRef = useRef();
  const inputRef = useRef();
  const { setErrFlag, setLoadFlag } = useContext(AppContext);

  useEffect(() => {
    getCommonList(type, setErrFlag, setLoadFlag)
      .then((result) => result && setCol(result[0]))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  useEffect(() => {
    if (value) {
      const selectedItems = tableList.filter((tableItem) =>
        value.some((selectedItem) => selectedItem.guid === tableItem.guid)
      );
      setSelectedCode(selectedItems);
    }
  }, [value, tableList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        comboTableRef.current &&
        !comboTableRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setTableClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const tableOpen = () => {
    setTableClicked(!tableClicked);
  };

  const deleteClick = (index) => {
    const deleteList = selectedCode.filter((item, i) => index !== i);

    deleteData(deleteList);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          width: "85%",
          borderBottom: "1px solid gray",
          position: "relative",
          "&:hover": {
            borderBottom: "1px solid black",
          },
        }}
        onClick={(event) => {
          tableOpen();
          event.stopPropagation();
        }}
        ref={inputRef}
      >
        {selectedCode &&
          col &&
          selectedCode.map((item, index) => (
            <Chip
              key={index}
              label={item[showValue]}
              onDelete={(e) => (e.stopPropagation(), deleteClick(index))}
              sx={{ mr: "1px", mb: "2px" }}
            />
          ))}
        <Input
          sx={{ flex: 1, minWidth: "30px" }}
          onClick={(event) => {
            tableOpen();
            event.stopPropagation();
          }}
          onChange={(event) => event.stopPropagation()}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={(event) => {
                  tableOpen();
                  event.stopPropagation();
                }}
              >
                {tableClicked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </InputAdornment>
          }
          disableUnderline
          multiline
        />
      </Box>
      {tableClicked && col && (
        <Paper
          sx={{
            position: "absolute",
            p: 0.5,
            zIndex: 9999,
            width: { width },
          }}
        >
          <TableContainer
            ref={comboTableRef}
            sx={{
              borderRadius: 1,
              maxHeight: "35vh",
              overflowX: "auto",
            }}
          >
            <Table className="combo-table">
              <TableHead
                sx={{
                  backgroundColor: "#D9DED8",
                }}
              >
                <TableRow>
                  <TableCell>{col.value1Char}</TableCell>
                  <TableCell>{col.value2Char}</TableCell>
                  <TableCell>{col.value3Char}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableList &&
                  tableList.map((item) => (
                    <TableRow
                      hover
                      key={item.guid}
                      onClick={(e) => {
                        changeData(item);
                        e.stopPropagation();
                      }}
                    >
                      <TableCell>{item.value1}</TableCell>
                      <TableCell>{item.value2}</TableCell>
                      <TableCell>{item.value3}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
}
