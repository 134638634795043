const apiUrl = process.env.REACT_APP_API_URL;

export const getCommonList = async (type, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/common/commonList?type=${type}`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          commonSid: row.commonSid,
          commonSeq: row.commonSeq,
          commonType: row.commonType,
          value1Char: row.value1Char,
          value2Char: row.value2Char,
          value3Char: row.value3Char,
          value4Char: row.value4Char,
          changeFlag: "N",
          usableFlag: row.usableFlag,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setCommonSave = async (data, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/common/commonSave`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setCommonDelete = async (commonSid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/common/commonDelete?commonGuid=${commonSid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
export const getCommonSubList = async (commonSid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/common/commonSubList?commonGuid=${commonSid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          commonSubSid: row.commonSubSid,
          commonSid: row.commonSid,
          commonSeq: row.commonSeq,
          commonSubType: row.commonSubType,
          value1Char: row.value1Char,
          value2Char: row.value2Char,
          value3Char: row.value3Char,
          value4Char: row.value4Char,
          value5Char: row.value5Char,
          value6Char: row.value6Char,
          value7Char: row.value7Char,
          value8Char: row.value8Char,
          changeFlag: "N",
          usableFlag: row.usableFlag,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setCommonSubSave = async (data, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/common/commonSubSave`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setCommonSubDelete = async (
  commonSubSid,
  setErrFlag,
  setLoadFlag
) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/common/commonSubDelete?commonSubGuid=${commonSubSid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
//메인타입 기준 서브 전체 조회
export const getCommonTypeSubAll = async (
  mainType,
  setErrFlag,
  setLoadFlag
) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/common/commonSubTypeAll?type=${mainType}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data;
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
//메인 타입, 서브 타입 동시 적용 리스트
export const getCommonTypeSubType = async (
  mainType,
  subType,
  setErrFlag,
  setLoadFlag
) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/common/commonSubType?mainType=${mainType}&subType=${subType}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data;
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
