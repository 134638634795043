import { Box, Button, Tab, Tabs, Typography, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, { useEffect, useRef, useState } from "react";
import AlertMain from "../Alert/AlertMain";
import moment from "moment";
import CalendarCust from "../Util/CalendarCust";
import DgTable from "./DgTable";
import "../Main/Table.css";
import { getMawbInfoAdmin } from "../API/SpcAPI";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import { useParams } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DgList = () => {
  const { value1, value2 } = useParams();
  const { setErrFlag, setLoadFlag, dgFlag, setDgFlag, alarmData } =
    useContext(AppContext);
  const [date, setDate] = useState();
  const [data, setData] = useState([]);
  const [fltCalendar, setFltCalendar] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const calendarRef = useRef();

  useEffect(() => {
    if (value1 && value2) {
      const year = value1.slice(0, 4);
      const month = value1.slice(4, 6);
      const day = value1.slice(6, 8);

      // `YYYY-MM-DD` 형식으로 변환
      const formattedDateString = `${year}-${month}-${day}`;
      setDate(formattedDateString);
      if (value2 === "T1") {
        setTabValue(0);
      } else {
        setTabValue(1);
      }
    } else {
      const nowDate = getTodayDate();
      setDate(nowDate);
    }
  }, [value1, value2]);

  useEffect(() => {
    const fetchData = () => {
      if (date) {
        const userGuid = sessionStorage.getItem("userSid");
        const convDate = date.replaceAll("-", "");
        const terminal = tabValue === 0 ? "T1" : "T2";
        getMawbInfoAdmin(convDate, userGuid, terminal, setErrFlag, setLoadFlag)
          .then((result) => result && setData(result))
          .catch((error) => setErrFlag({ code: "Y", data: error.message }));
      }
    };

    fetchData(); // 처음에 한 번 실행

    const interval = setInterval(fetchData, 30 * 60 * 1000); // 30분마다 실행

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 타이머 정리
  }, [date, tabValue, dgFlag, value1, alarmData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setFltCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const changeDate = (days) => {
    setDate((prevDate) => {
      const date = moment(prevDate, "YYYY-MM-DD").toDate();
      const newDate = moment(date).add(days, "days").format("YYYY-MM-DD");
      return newDate;
    });
  };

  const changeMonth = (months) => {
    setDate((prevDate) => {
      const date = moment(prevDate, "YYYY-MM-DD").toDate();
      const newDate = moment(date).add(months, "months").format("YYYY-MM-DD");
      return newDate;
    });
  };

  const onClickFltDate = (e) => {
    e.stopPropagation();
    setFltCalendar(!fltCalendar);
  };

  const changeFltDate = (newDate) => {
    setDate(moment(newDate).format("YYYY-MM-DD"));
    setFltCalendar(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <div style={{ height: 110 }}>
        <AlertMain />
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 5,
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton onClick={() => changeMonth(-1)}>
              <KeyboardDoubleArrowLeftIcon sx={{ color: "black" }} />
            </IconButton>
            <IconButton onClick={() => changeDate(-1)}>
              <KeyboardArrowLeftIcon sx={{ color: "black" }} />
            </IconButton>
            <Box ref={calendarRef}>
              <Button
                sx={{
                  color: "black",
                  fontSize: 20,
                  fontWeight: "bold",
                  fontFamily: "Noto Sans KR",
                }}
                onClick={(e) => onClickFltDate(e)}
              >
                {date}
              </Button>
              {fltCalendar && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 9999,
                    top: "calc(100% + 10px)",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <CalendarCust props={(date) => changeFltDate(date)} />
                </Box>
              )}
            </Box>

            <IconButton onClick={() => changeDate(1)}>
              <KeyboardArrowRightIcon sx={{ color: "black" }} />
            </IconButton>
            <IconButton onClick={() => changeMonth(1)}>
              <KeyboardDoubleArrowRightIcon sx={{ color: "black" }} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "90vw",
            }}
          >
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="T1" {...a11yProps(0)} />
              <Tab label="T2" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        {tabValue === 0 ? (
          <CustomTabPanel value={tabValue} index={0}>
            <DgTable dgData={data} />
          </CustomTabPanel>
        ) : (
          <CustomTabPanel value={tabValue} index={1}>
            <DgTable dgData={data} />
          </CustomTabPanel>
        )}
      </Box>
    </div>
  );
};
export default DgList;
