const apiUrl = process.env.REACT_APP_API_URL;
export const getDeptAllList = async (setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/dept/deptList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          deptSid: row.deptSid,
          deptSeq: row.deptSeq,
          parentDeptSid: row.parentDeptSid,
          userSid: row.userSid,
          deptName: row.deptName,
          changeFlag: "N",
          terminal: row.terminal,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getDeptMostList = async () => {
  try {
    const response = await fetch(`${apiUrl}/dept/deptMostList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          deptSid: row.deptSid,
          deptSeq: row.deptSeq,
          parentDeptGuid: row.parentDeptGuid,
          userSid: row.userSid,
          deptName: row.deptName,
          changeFlag: "N",
          terminal: row.terminal,
        }));
        return data;
      }
    }
  } catch (error) {}
  return false;
};

export const getDeptMostListDropDown = async () => {
  try {
    const response = await fetch(`${apiUrl}/dept/deptMostList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
      } else {
        const data = res.data.map((row, index) => ({
          value1: index,
          guid: row.deptSid,
          value3: row.userSid ? row.userSid : "",
          value2: row.deptName,
        }));
        return data;
      }
    }
  } catch (error) {}
  return false;
};

export const getDeptLowUserListExcept = async (deptGuid) => {
  try {
    const response = await fetch(
      `${apiUrl}/dept/deptRowList?deptGuid=${deptGuid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
      } else {
        const data = res.data.map((row, index) => ({
          value1: index,
          guid: row.deptSid,
          value2: row.deptName,
        }));
        return data;
      }
    }
  } catch (error) {}
  return false;
};

export const setDeptSave = async (data, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/dept/deptSave`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setDeptDelete = async (deptGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/dept/deptDelete?deptGuid=${deptGuid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getDeptMe = async (userGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/dept/validDept?userGuid=${userGuid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return res.data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
