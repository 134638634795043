import { Box, Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

const GetMonth = ({ propsMonth, selectMonth }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
    selectMonth(month);
  };
  useEffect(() => {
    setSelectedMonth(propsMonth);
  }, [propsMonth]);
  return (
    <Paper elevation={3}>
      <Box>
        <Button
          variant={selectedMonth === 1 ? "contained" : "text"}
          onClick={() => handleMonthClick(1)}
        >
          1월
        </Button>
        <Button
          variant={selectedMonth === 2 ? "contained" : "text"}
          onClick={() => handleMonthClick(2)}
        >
          2월
        </Button>
        <Button
          variant={selectedMonth === 3 ? "contained" : "text"}
          onClick={() => handleMonthClick(3)}
        >
          3월
        </Button>
      </Box>
      <Box>
        <Button
          variant={selectedMonth === 4 ? "contained" : "text"}
          onClick={() => handleMonthClick(4)}
        >
          4월
        </Button>
        <Button
          variant={selectedMonth === 5 ? "contained" : "text"}
          onClick={() => handleMonthClick(5)}
        >
          5월
        </Button>
        <Button
          variant={selectedMonth === 6 ? "contained" : "text"}
          onClick={() => handleMonthClick(6)}
        >
          6월
        </Button>
      </Box>
      <Box>
        <Button
          variant={selectedMonth === 7 ? "contained" : "text"}
          onClick={() => handleMonthClick(7)}
        >
          7월
        </Button>
        <Button
          variant={selectedMonth === 8 ? "contained" : "text"}
          onClick={() => handleMonthClick(8)}
        >
          8월
        </Button>
        <Button
          variant={selectedMonth === 9 ? "contained" : "text"}
          onClick={() => handleMonthClick(9)}
        >
          9월
        </Button>
      </Box>
      <Box>
        <Button
          variant={selectedMonth === 10 ? "contained" : "text"}
          onClick={() => handleMonthClick(10)}
        >
          10월
        </Button>
        <Button
          variant={selectedMonth === 11 ? "contained" : "text"}
          onClick={() => handleMonthClick(11)}
        >
          11월
        </Button>
        <Button
          variant={selectedMonth === 12 ? "contained" : "text"}
          onClick={() => handleMonthClick(12)}
        >
          12월
        </Button>
      </Box>
    </Paper>
  );
};

export default GetMonth;
