const apiUrl = process.env.REACT_APP_API_URL;

export const getAirList = async (setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/air/airList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          airSid: row.airSid,
          airSeq: row.airSeq,
          carrier2Code: row.carrier2Code,
          carrier3Code: row.carrier3Code,
          terminalCode: row.terminalCode,
          nationCode: row.nationCode,
          airPhone: row.airPhone,
          prefix: row.prefix,
          airName: row.airName,
          changeFlag: "N",
          usableFlag: row.usableFlag,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setAirSave = async (data, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/air/airSave`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setAirDelete = async (airSid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/air/airDelete?airSid=${airSid}`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getAirSphList = async (airSid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/air/airSphList?airSid=${airSid}`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          airSphGuid: row.airSpcSid,
          airSid: row.airSid,
          sphSeq: row.sphSeq,
          sphType: row.sphType,
          sphValue1: row.sphValue1,
          changeFlag: "N",
          usableFlag: row.usableFlag,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setAirSphSave = async (data, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/air/airSphSave`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setAirSphDelete = async (airSphGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/air/airSphDelete?airSpcSid=${airSphGuid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setAirSphAllDelete = async (
  airSid,
  type,
  setErrFlag,
  setLoadFlag
) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(
      `${apiUrl}/air/airSphAllDelete?airSid=${airSid}&type=${type}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getAirCombo = async () => {
  try {
    const response = await fetch(`${apiUrl}/air/airList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
      } else {
        const data = res.data.map((row, index) => ({
          value1: index,
          guid: row.airSid,
          value2: row.carrier2Code,
          value3: row.airName,
          value4: row.terminalCode,
        }));
        return data;
      }
    }
  } catch (error) {}
  return false;
};

export const getAirSphListAll = async (setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const response = await fetch(`${apiUrl}/air/airSphListAll`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          airSphGuid: row.airSpcSid,
          airSid: row.airSid,
          sphSeq: row.sphSeq,
          sphType: row.sphType,
          sphValue1: row.sphValue1,
          usableFlag: row.usableFlag,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
