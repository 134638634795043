const apiUrl = process.env.REACT_APP_API_URL;

export const getINTRACombo = async () => {
  try {
    const response = await fetch(`${apiUrl}/intra/intCombo`, {
      method: "GET",
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          guid: row[0],
          value1: row[1],
        }));
        return data;
      }
    }
  } catch (error) {
    console.log(error.data);
  }
  return false;
};

export const getAlarmMasterUserList = async (
  userGuid,
  intraGuid,
  setErrFlag,
  setLoadFlag
) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(
      `${apiUrl}/alarm/masterAlarmList?userGuid=${userGuid}&intraGuid=${intraGuid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          type: row.type,
          guid: row.guid,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }

  setLoadFlag(false);
  return false;
};

export const getAlarmInfo = async (alarmGuid, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(
      `${apiUrl}/alarm/alarmInfo?alarmGuid=${alarmGuid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = { value1: res.data[0], value2: res.data[1] };
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }

  setLoadFlag(false);
  return false;
};

export const setAlarmMasterSave = async (dto, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(`${apiUrl}/alarm/alarmMasterSave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dto),
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setAlarmRead = async (dto, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const response = await fetch(`${apiUrl}/alarm/alarmRead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dto),
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
