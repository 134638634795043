import {
  Box,
  Checkbox,
  Modal,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  TextField,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import React, { useRef, useEffect, useState } from "react";
import MainModal from "./MainModal";
import BasicTable from "../Chart/BasicTable";
import "./Table.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import StarIcon from "@mui/icons-material/Star";
import { AppContext } from "../AppContext";
import { personalRowAddClick, rowDel, masterSave } from "../API/MainAPI";
import { useContext } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import dayjs from "dayjs";
import TableCombo from "../Util/TableCombo";

function PersonalTable({ viewYearMonth, dataArray, dptcdCombo }) {
  const [personalData, setPersonalData] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openRowModal, setOpenRowModal] = useState(false);
  const [editingRow, setEditingRow] = React.useState(null);
  const [editedValues, setEditedValues] = React.useState({});
  const [editIndex, setEditIndex] = useState();
  const [intraGuid, setIntraGuid] = useState("");
  const [deptClicked, setDeptClicked] = useState({});
  const { setErrFlag, setLoadFlag, setMainFlag, monValue } =
    useContext(AppContext);
  const tableRef = useRef(null);
  const comboTableRef = useRef();
  const navigator = useNavigate();

  const addClick = async () => {
    const userGuid = sessionStorage.getItem("userSid");

    if (!userGuid) {
      navigator("/");
    }

    if (await personalRowAddClick(userGuid, setErrFlag, setLoadFlag)) {
      setMainFlag(true);
    }
  };

  useEffect(() => {
    const data = dataArray.filter(
      (item) => item.dataStatus === "P" && item.intraCompleteFlag === "N"
    );
    setPersonalData(data);
  }, [dataArray]);

  const handleOpen = async (index) => {
    setIntraGuid(personalData[index].intraGuid);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRowClose = () => {
    setOpenRowModal(false);
    setEditIndex(undefined);
    setMainFlag(true);
  };

  const handleEdit = (index) => {
    setEditingRow(index);
    const initialEditedValues = personalData[index];
    setEditedValues(initialEditedValues);
  };

  const handleDelete = (index) => {
    const data = personalData[index].intraGuid;
    rowDel(data, setErrFlag, setLoadFlag)
      .then((result) => result && setMainFlag(true))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const handleCancelEdit = () => {
    setEditingRow(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const menuElement = document.querySelector(".intraDept");

      if (
        tableRef.current &&
        !tableRef.current.contains(event.target) &&
        (!menuElement || !menuElement.contains(event.target)) // menuElement 내부 클릭 예외 처리
      ) {
        handleCancelEdit();
      }

      if (
        comboTableRef.current &&
        !comboTableRef.current.contains(event.target)
      ) {
        setDeptClicked({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSave = (index) => {
    const data = editedValues[index] || {};
    const sendUserGuid = sessionStorage.getItem("userSid");
    const intraGuid = personalData[index].intraGuid;

    const saveData = {
      intraSid: intraGuid,
      sendUserSid: sendUserGuid,
      intraStartTime: data.intraStartTime || personalData[index].intraStartTime,
      intraImportantFlag:
        data && data.intraImportantFlag > 0
          ? data.intraImportantFlag
            ? "Y"
            : "N"
          : personalData[index].intraImportantFlag,
      masterSubject:
        data && data.intraSubject
          ? data.intraSubject
          : personalData[index].intraSubject,
      intraDept:
        data && data.intraDept ? data.intraDept : personalData[index].intraDept,

      intraCompleteFlag: data.intraCompleteFlag === "on" ? "Y" : "N",
    };

    masterSave(saveData, setErrFlag, setLoadFlag)
      .then(
        (result) =>
          result && (setMainFlag(true), handleCancelEdit(), handleOpen(index))
      )
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };
  const handleDateChange = (newValue, columnIndex, columnName) => {
    setEditedValues({
      ...editedValues,
      [columnIndex]: {
        ...editedValues[columnIndex],
        [columnName]: newValue,
      },
    });

    if (columnName === "intraDept") {
      setDeptClicked({});
    }
  };

  const checkHandleChange = (e, columnIndex, columnName) => {
    setEditedValues({
      ...editedValues,
      [columnIndex]: {
        ...editedValues[columnIndex],
        [columnName]: e,
      },
    });
  };

  const handleChange = (e, columnIndex, columnName) => {
    const { value } = e.target;
    setEditedValues({
      ...editedValues,
      [columnIndex]: {
        ...editedValues[columnIndex],
        [columnName]: value,
      },
    });
  };

  const selectRow = (index) => {
    setEditingRow(null);
    const data = personalData[index].intraGuid;
    setEditIndex(data);
    setOpenRowModal(true);
  };
  const dataReFlagChange = (flag) => {
    if (flag !== undefined || flag !== null) {
      handleClose();
      setEditingRow(null);
      setMainFlag(true);
    }
  };

  const deptTableOpen = (index) => {
    setDeptClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Paper sx={{ mt: 5, pr: 2, pl: 2, pb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
            mb: 2,
            ml: 2,
          }}
        >
          <Typography className="menuTitle">개인</Typography>
        </Box>
        <TableContainer
          sx={{ borderRadius: 1, width: "90vw", maxHeight: "60vh" }}
          ref={tableRef}
        >
          <Table className="table">
            <TableHead sx={{ backgroundColor: "#e0ebd6" }}>
              <TableRow>
                <TableCell sx={{ width: "3%" }}>중요도</TableCell>
                <TableCell sx={{ width: "3%" }}>No</TableCell>
                <TableCell sx={{ width: "15%" }}>일정명</TableCell>
                <TableCell sx={{ width: "7%" }}>시작시간</TableCell>
                <TableCell sx={{ width: "10%" }}>요청부서</TableCell>
                <TableCell sx={{ width: "15%" }}>세부일정명</TableCell>
                {Array.from(
                  { length: viewYearMonth },
                  (_, index) => index + 1
                ).map((day) => (
                  <TableCell key={day} sx={{ fontSize: 11, width: "1.2%" }}>
                    {day}
                  </TableCell>
                ))}
                {editingRow !== null && <TableCell>완료</TableCell>}
                <TableCell sx={{ width: "5%" }}>편집</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!(personalData.length === 0) &&
                personalData.map((row, index) => (
                  <TableRow
                    hover
                    key={index}
                    onClick={(event) => (
                      event.stopPropagation(),
                      personalData.length !== 0 && selectRow(index)
                    )}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSave(index);
                      }
                    }}
                  >
                    <TableCell>
                      {editingRow === index ? (
                        <Checkbox
                          id={`intraImportantFlag-${index}`}
                          checked={
                            editedValues[index] &&
                            editedValues[index].intraImportantFlag
                              ? editedValues[index].intraImportantFlag === true
                                ? true
                                : false
                              : row.intraImportantFlag === "Y"
                              ? true
                              : false
                          }
                          onClick={(event) => {
                            event.stopPropagation();
                            checkHandleChange(
                              event.target.checked,
                              index,
                              "intraImportantFlag"
                            );
                          }}
                        />
                      ) : row.intraImportantFlag === "Y" ? (
                        <StarIcon sx={{ color: "#FF7070" }}></StarIcon>
                      ) : null}
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {editingRow === index ? (
                        <TextField
                          sx={{ width: "90%" }}
                          size="small"
                          value={
                            editedValues[index]
                              ? editedValues[index].intraSubject
                              : row.intraSubject
                          }
                          onChange={(e) => (
                            e.stopPropagation(),
                            handleChange(e, index, "intraSubject")
                          )}
                          onClick={(e) => e.stopPropagation()}
                        />
                      ) : (
                        row.intraSubject
                      )}
                    </TableCell>
                    <TableCell>
                      {editingRow === index ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimeField
                            size="small"
                            format="MM-DD HH:mm"
                            value={
                              editedValues[index]?.intraStartTime
                                ? dayjs(editedValues[index].intraStartTime)
                                : dayjs(personalData[index].intraStartTime)
                            }
                            onChange={(newValue) => {
                              handleDateChange(
                                newValue,
                                index,
                                "intraStartTime"
                              );
                            }}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </LocalizationProvider>
                      ) : (
                        moment(row.intraStartTime).format("MM-DD HH:mm")
                      )}
                    </TableCell>
                    <TableCell>
                      {editingRow === index ? (
                        <Box
                          sx={{
                            justifyContent: "center",
                            paddingLeft: 1,
                          }}
                        >
                          <OutlinedInput
                            size="small"
                            className="admin-input"
                            variant="outlined"
                            value={
                              editedValues[index] &&
                              editedValues[index].intraDept
                                ? dptcdCombo
                                    .filter(
                                      (item) =>
                                        item.guid ===
                                        editedValues[index].intraDept
                                    )
                                    .map((item) => item.value2)
                                : dptcdCombo
                                    .filter(
                                      (item) => item.guid === row.intraDept
                                    )
                                    .map((item) => item.value2)
                            }
                            onClick={(event) => {
                              deptTableOpen(index);
                              event.stopPropagation();
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(event) => {
                                    deptTableOpen(index);
                                    event.stopPropagation();
                                  }}
                                >
                                  {deptClicked[index] ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Box ref={deptClicked[index] ? comboTableRef : null}>
                            {deptClicked[index] && (
                              <TableCombo
                                tableList={dptcdCombo}
                                changeData={(value) =>
                                  handleDateChange(value, index, "intraDept")
                                }
                                type={"DEPT"}
                                width={"15%"}
                              />
                            )}
                          </Box>
                        </Box>
                      ) : (
                        row.intraDeptStr
                      )}
                    </TableCell>
                    <TableCell>
                      {row.dateData.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 0,
                            borderBottom: "none", // 필요에 따라 테이블 셀 경계를 조정
                          }}
                        >
                          <TableCell
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 0,
                              borderBottom: "none", // 필요에 따라 테이블 셀 경계를 조정
                              height: "25px", // 임시 고정 값
                            }}
                          >
                            {row.contents !== null ? row.contents : "\u00A0"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableCell>
                    {Array.from(
                      { length: viewYearMonth },
                      (_, index) => index + 1
                    ).map((day) => (
                      <TableCell sx={{ padding: 0 }} key={day}>
                        {row.dateData.map((item, itemIndex) => (
                          <TableRow
                            sx={{
                              padding: 0,
                              display: "contents",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            key={item}
                          >
                            <Box
                              sx={{
                                height: "23px",
                                mt: "2px",

                                backgroundColor: (() => {
                                  let color = "none";

                                  const itemSubArray = item.dateValue;
                                  if (itemSubArray) {
                                    for (
                                      let i = 0;
                                      i < itemSubArray.length;
                                      i++
                                    ) {
                                      if (
                                        itemSubArray[i].day === day &&
                                        monValue === itemSubArray[i].mon
                                      ) {
                                        switch (itemIndex % 5) {
                                          case 0:
                                            color = "#264653";
                                            break;
                                          case 1:
                                            color = "#2a9d8f";
                                            break;
                                          case 2:
                                            color = "#e9c46a";
                                            break;
                                          case 3:
                                            color = "#f4a261";
                                            break;
                                          case 4:
                                            color = "#e76f51";
                                            break;
                                          default:
                                            color = "none";
                                            break;
                                        }
                                        break;
                                      }
                                    }
                                  }
                                  return color;
                                })(),
                              }}
                            >
                              &nbsp;
                            </Box>
                          </TableRow>
                        ))}
                      </TableCell>
                    ))}

                    {/* 완료 여부 체크 박스 */}
                    {editingRow !== null &&
                      (editingRow === index ? (
                        <TableCell>
                          <Checkbox
                            id={`intraCompleteFlag-${index}`}
                            checked={editedValues[index]?.intraCompleteFlag}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleChange(event, index, "intraCompleteFlag");
                            }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      ))}
                    <TableCell align="center">
                      {editingRow === index ? (
                        <IconButton
                          aria-label="save"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleSave(index);
                          }}
                        >
                          <CheckIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                      ) : (
                        <React.Fragment>
                          <IconButton
                            aria-label="edit"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleEdit(index);
                            }}
                          >
                            <EditIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDelete(index);
                            }}
                          >
                            <DeleteIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </React.Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <IconButton
          onClick={addClick}
          sx={{ position: "fixed", bottom: 20, right: 30 }}
        >
          <AddCircleIcon sx={{ fontSize: 50, color: "#4A7E4F" }} />
        </IconButton>
      </Paper>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <MainModal
            intraGuid={intraGuid}
            dataReFlag={dataReFlagChange}
            handleClose={handleClose}
          />
        </Box>
      </Modal>

      <Modal
        open={openRowModal}
        onClose={handleRowClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <BasicTable intraGuid={editIndex} />
        </Box>
      </Modal>
    </Box>
  );
}

export default PersonalTable;
