import {
  Box,
  Button,
  Chip,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TablePagination,
  Modal,
  Snackbar,
  Alert,
  IconButton,
  Collapse,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReplyIcon from "@mui/icons-material/Reply";
import ReplayIcon from "@mui/icons-material/Replay";
import FilterList from "../Util/FilterList";
import DgPost from "./DgPost";
import "../Main/Table.css";
import { AppContext } from "../AppContext";
import { getCommonTypeSubType } from "../API/CommonAPI";
import { getDeptMe } from "../API/DeptAPI";
import { getAirCombo, getAirSphListAll } from "../API/AirAPI";
import DgModal from "./DgModal";
import { useNavigate } from "react-router-dom";

import { CssTextField, EmtButton } from "../Util/ConvMui";

const DgTable = ({ dgData }) => {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [mawb, setMawb] = useState("");
  const [mawbError, setMawbError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);
  const [crrFilter, setCrrFilter] = useState([]);
  const [contentFilter, setContentFilter] = useState([]);
  const [resetFlag, setResetFlag] = useState(false);
  const [postIndex, setPostIndex] = useState();
  const [openRowModal, setOpenRowModal] = useState(false);
  const [airList, setAirList] = useState([]);
  const [airSpcList, setAirSpcList] = useState([]);
  const [sphTypes, setSphTypes] = useState(new Set());
  const [spc1Data, setSpc1Data] = useState([]);
  const [spc2Data, setSpc2Data] = useState([]);
  const [spcData, setSpcData] = useState([]);
  const [emailAlert, setEmailAlert] = useState(false);
  const [emailErrAlert, setEmailErrAlert] = useState(false);
  const [open, setOpen] = React.useState(false);
  const apiUrl = process.env.REACT_APP_API_CARGOLINK;
  const navigator = useNavigate();

  useEffect(() => {
    getDeptMe(sessionStorage.getItem("userSid"), setErrFlag, setLoadFlag)
      .then((result) =>
        result && result === "Y"
          ? getAirCombo()
              .then((result) => result && setAirList(result))
              .catch((error) => setErrFlag({ code: "Y", data: error.message }))
          : setAirList(null)
      )
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));

    getAirSphListAll(setErrFlag, setLoadFlag)
      .then((result) => result && setAirSpcList(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  useEffect(() => {
    getCommonTypeSubType("SPCCD", "SPC1", setErrFlag, setLoadFlag)
      .then((result) => result && contentChange1(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));

    getCommonTypeSubType("SPCCD", "SPC2", setErrFlag, setLoadFlag)
      .then((result) => result && contentChange2(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));

    const contentChange1 = (data) => {
      const convData = [
        { guid: data[0].value1Char },
        { guid: data[0].value2Char },
        { guid: data[0].value3Char },
        { guid: data[0].value4Char },
        { guid: data[0].value5Char },
        { guid: data[0].value6Char },
        { guid: data[0].value7Char },
        { guid: data[0].value8Char },
      ];
      setSpc1Data(convData);
    };
    const contentChange2 = (data) => {
      const convData = [
        { guid: data[0].value1Char },
        { guid: data[0].value2Char },
        { guid: data[0].value3Char },
        { guid: data[0].value4Char },
        { guid: data[0].value5Char },
        { guid: data[0].value6Char },
        { guid: data[0].value7Char },
        { guid: data[0].value8Char },
      ].filter((value) => value.guid !== null);
      setSpc2Data(convData);
    };
  }, []);

  useEffect(() => {
    const combinedData = [...spc1Data, ...spc2Data];
    setSpcData(combinedData);
  }, [spc1Data, spc2Data]);

  useEffect(() => {
    if (airSpcList && (contentFilter || crrFilter) && dgData) {
      var filtered = null;
      if (contentFilter.length > 0 && crrFilter.length === 0) {
        filtered = dgData.filter((item) =>
          contentFilter.some((value) => item.codeList?.includes(value))
        );
        setFilterData(filtered);
        return;
      } else if (contentFilter.length === 0 && crrFilter.length > 0) {
        filtered = dgData.filter(
          (item) => crrFilter.includes(item.mainEntities.airSid) && item
        );
        setFilterData(filtered);
        return;
      } else if (contentFilter.length > 0 && crrFilter.length > 0) {
        filtered = dgData.filter(
          (item) =>
            crrFilter.includes(item.mainEntities.airSid) &&
            contentFilter?.some((value) => item.codeList?.includes(value)) &&
            item
        );
      } else if (contentFilter.length === 0 && crrFilter.length === 0) {
        filtered = dgData;
      }

      if (filtered.length === 0) {
        if (contentFilter.length > 0 || crrFilter.length > 0) {
          setFilterData(filtered);
        } else {
          setFilterData(dgData);
        }
      } else {
        setFilterData(filtered);
      }
    }
  }, [contentFilter, dgData, crrFilter]);

  const handleMawbChange = (event) => {
    let value = event.target.value.replace(/\D/g, "").replace("-", "");
    if (value.length === 12) {
      return;
    }

    if (value.length <= 7) {
      value = value.replace(/^(\d{3})(\d{1,4})$/, "$1-$2");
    }

    if (value.length <= 11) {
      value = value.replace(/^(\d{3})(\d{4})(\d{1,4})$/, "$1-$2-$3");
    }
    setMawb(value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCarrierFilterChange = (newFilter) => {
    setCrrFilter(newFilter);
  };

  const handleContentFilterChange = (newFilter) => {
    setContentFilter(newFilter);
  };

  const handleReset = () => {
    setMawb("");
    setCrrFilter([]);
    setContentFilter([]);
    setResetFlag(true);
  };

  const handleSearchClick = () => {
    if (mawb.replaceAll("-", "").length === 11 || mawb === "") {
      setMawbError(false);
      if (mawb === null || mawb === "") {
        setFilterData(dgData);
      } else {
        setFilterData(
          dgData.filter(
            (item) =>
              item.mainEntities.masterAirWayBillNo === mawb.replaceAll("-", "")
          )
        );
      }
    } else {
      setMawbError(true);
      setFilterData(dgData);
    }
  };

  const selectRow = (index) => {
    const data = filterData[index].mainEntities.agencySid;
    setPostIndex(data);
    setOpenRowModal(true);
  };

  const handleRowClose = () => {
    setOpenRowModal(false);
  };

  const handleEmailAlert = () => {
    setEmailAlert(true);
  };

  const handleEmailErrAlert = () => {
    setEmailErrAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEmailAlert(false);
  };

  const handleErrAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEmailErrAlert(false);
  };

  const colOpen = (agencyGuid) => {
    if (open === agencyGuid) {
      setOpen("");
    } else {
      setOpen(agencyGuid);
    }
  };

  const handleEdit = (rowMawb) => {
    navigator(`localhost:3001/SpcInfo/U/${rowMawb}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 1,
          pb: 2,
          pl: 1.5,
          pr: 1.5,
        }}
        elevation={0}
      >
        <Box sx={{ display: "flex", width: "90vw", alignItems: "flex-end" }}>
          <TableContainer>
            <Table className="searchTable">
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: "Noto Sans KR",
                      fontWeight: 500,
                      fontSize: "1rem",
                      width: "7%",
                      textAlign: "center",
                    }}
                  >
                    MAWB
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CssTextField
                      id="mawbText"
                      variant="standard"
                      value={mawb.replace(/^(\d{3})(\d{4})(\d+)$/, `$1-$2-$3`)}
                      required
                      onChange={handleMawbChange}
                      error={mawbError}
                      helperText={mawbError ? "MAWB를 확인하세요." : ""}
                      autoComplete="off"
                      inputProps={{ sx: { textAlign: "left !important" } }}
                    />
                    <EmtButton
                      variant="outlined"
                      sx={{
                        ml: 3,
                        pl: 2,
                      }}
                      onClick={handleSearchClick}
                      endIcon={<SearchIcon />}
                    >
                      조회
                    </EmtButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                fontFamily: "Noto Sans KR",
                color: "gray",
                fontSize: "0.9rem",
              }}
            >
              * MAWB, 항공사, 항목 초기화
            </Typography>

            <IconButton>
              <ReplayIcon sx={{ color: "gray" }} onClick={handleReset} />
            </IconButton>
          </Box>
        </Box>
        <TableContainer
          sx={{
            borderRadius: 1,
            width: "90vw",
            mt: 1,
          }}
        >
          <Table className="dg-table">
            <TableHead sx={{ backgroundColor: "#C7DBDC" }}>
              <TableRow>
                <TableCell sx={{ width: "1.5%" }}></TableCell>
                <TableCell sx={{ width: "1.5%" }}></TableCell>
                <TableCell sx={{ width: "1.5%" }}>No</TableCell>
                <TableCell sx={{ width: "5%" }}>날짜</TableCell>
                {airList && (
                  <TableCell
                    sx={{
                      width: "7%",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ ml: 5 }}>항공사</Box>
                      <FilterList
                        filterList={airList}
                        viewStr={"value2"}
                        onFilterChange={handleCarrierFilterChange}
                        resetFlag={resetFlag}
                        setResetFlag={setResetFlag}
                        width="8vw"
                      />
                    </Box>
                  </TableCell>
                )}

                <TableCell sx={{ width: "10%" }}>MAWB</TableCell>
                <TableCell
                  sx={{
                    width: "7%",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ ml: 5 }}>항목</Box>
                    <FilterList
                      filterList={spcData}
                      viewStr={"guid"}
                      onFilterChange={handleContentFilterChange}
                      resetFlag={resetFlag}
                      setResetFlag={setResetFlag}
                      width="10vw"
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ width: "8%" }}>상태</TableCell>
                <TableCell sx={{ width: "5%" }}>수정</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterData &&
                filterData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <React.Fragment>
                      <TableRow
                        hover
                        key={index}
                        onDoubleClick={(event) => (
                          event.stopPropagation(),
                          filterData.length !== 0 &&
                            selectRow(page * rowsPerPage + index)
                        )}
                      >
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={(event) => (
                              colOpen(row.mainEntities.agencySid),
                              event.stopPropagation()
                            )}
                          >
                            {open === row.mainEntities.agencySid ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {row.mainEntities.readFlag === "N" && (
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <FiberNewOutlinedIcon
                                sx={{ color: "red", fontSize: 30 }}
                              />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{row.mainEntities.agencyDate}</TableCell>
                        {airList && (
                          <TableCell>
                            {
                              airList.find(
                                (item) => item.guid === row.mainEntities.airSid
                              )?.value2
                            }
                          </TableCell>
                        )}
                        <TableCell>
                          {row.mainEntities.masterAirWayBillNo.replace(
                            /^(\d{3})(\d{4})(\d+)$/,
                            `$1-$2-$3`
                          )}
                        </TableCell>
                        <TableCell>
                          {[
                            ...new Set(
                              airSpcList
                                .filter((item) =>
                                  row.codeList.includes(item.sphType)
                                )
                                .map((item) => item.sphType)
                            ),
                          ].join(" / ")}
                        </TableCell>
                        <TableCell>
                          {row.mainEntities.agencyFlag === "W" && (
                            <Chip
                              sx={{ width: 90, fontFamily: "Noto Sans KR" }}
                              label="Awaiting"
                              color="primary"
                              variant="outlined"
                            />
                          )}
                          {row.mainEntities.agencyFlag === "Y" && (
                            <Chip
                              sx={{ width: 90, fontFamily: "Noto Sans KR" }}
                              label="Confirmed"
                              color="success"
                            />
                          )}
                          {row.mainEntities.agencyFlag === "N" && (
                            <Chip
                              sx={{ width: 90, fontFamily: "Noto Sans KR" }}
                              label="Rejected"
                              color="warning"
                            />
                          )}
                        </TableCell>
                        {/* 사이트 변경 해야 함 */}
                        <TableCell>
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              window.open(
                                `${apiUrl}/SpcInfo/U/${row.mainEntities.masterAirWayBillNo.replace(
                                  /^(\d{3})(\d{4})(\d+)$/,
                                  `$1-$2-$3`
                                )}`,
                                "_blank"
                              );
                            }}
                          >
                            <ReplyIcon sx={{ transform: "scaleX(-1)" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={12}
                        >
                          <Collapse
                            timeout="auto"
                            unmountOnExit
                            in={
                              open === row.mainEntities.agencySid ? true : false
                            }
                          >
                            <DgModal props={open > 0 && open} />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filterData && filterData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ mt: -1 }}
          />
        </TableContainer>
      </Paper>

      <Modal open={openRowModal} onClose={handleRowClose}>
        <Box>
          <DgPost
            agencyGuid={postIndex}
            airList={airList}
            handleRowClose={handleRowClose}
            handleEmailAlert={handleEmailAlert}
            handleEmailErrAlert={handleEmailErrAlert}
            spcData={airSpcList}
          />
        </Box>
      </Modal>

      <Snackbar
        open={emailAlert}
        autoHideDuration={3000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity="success"
          onClose={handleAlertClose}
          sx={{ width: "100%" }}
        >
          Reject 이메일이 성공적으로 전송되었습니다.
        </Alert>
      </Snackbar>

      <Snackbar
        open={emailErrAlert}
        autoHideDuration={3000}
        onClose={handleErrAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity="error"
          onClose={handleErrAlertClose}
          sx={{ width: "100%" }}
        >
          Reject 이메일 전송을 실패하였습니다.
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default DgTable;
