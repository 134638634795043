import { Box, Button, Popper, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, { useEffect, useRef, useState } from "react";
import AlertMain from "../Alert/AlertMain";
import GetMonth from "../Util/GetMonth";
import GetYear from "../Util/GetYear";
import PublicTable from "./PublicTable";
import CplPublicTable from "./CplPublicTable";
import CplPersonalTable from "./CplPersonalTable";
import PersonalTable from "./PersonalTable";
import { useNavigate, useParams } from "react-router-dom";
import { getMasterAllList, validJwt, getDPTCDDropDown } from "../API/MainAPI";
import { AppContext } from "../AppContext";
import { useContext } from "react";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Main = () => {
  const { value1, value2 } = useParams();
  const intervalIdRef = useRef(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [viewYearMonth, setViewYearMonth] = useState();
  const [open, setOpen] = useState(0);
  const [anchorElMonth, setAnchorElMonth] = useState(null);
  const [masterAllList, setMasterAllList] = useState([]);
  const [dptcdCombo, setDptcdCombo] = useState([]);
  const [value, setValue] = React.useState(0);
  const { setErrFlag, setLoadFlag, mainFlag, setMainFlag, setMonValue } =
    useContext(AppContext);

  useEffect(() => {
    if (value1 && value2) {
      if (value2 === "Y") {
        setValue(1);
      } else {
        setValue(0);
      }
      const year = value1.slice(0, 4);
      const month = value1.slice(4, 6);
      const day = value1.slice(6, 8);

      // `YYYY-MM-DD` 형식으로 변환
      const formattedDateString = `${year}-${month}-${day}`;
      const date = new Date(formattedDateString);
      setYear(date.getFullYear());
      setMonth(date.getMonth() + 1);
    }
  }, [value1, value2]);

  useEffect(() => {
    const date = getDaysInMonth(year, month);
    setViewYearMonth(date);
    setMonValue(month);
  }, [month, year, value1]);
  const getDaysInMonth = (year, month) => {
    const date = new Date(year, month - 1, 1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);
    return date.getDate();
  };
  const monthConvert = (month) => {
    var monthStr = String(month);
    if (monthStr.length == 1) {
      monthStr = "0" + monthStr;
    }
    return monthStr;
  };
  useEffect(() => {
    const userGuid = sessionStorage.getItem("userSid");

    const monthStr = monthConvert(month);
    const date = String(year) + monthStr;
    getMasterAllList(userGuid, date, setErrFlag, setLoadFlag)
      .then((result) =>
        result === false ? setMasterAllList([]) : setMasterAllList(result)
      )
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    setMainFlag(false);
  }, [mainFlag, year, month]);

  useEffect(() => {
    getDPTCDDropDown()
      .then((result) => result && setDptcdCombo(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  const beforeMonth = () => {
    if (month === 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
    setOpen(0);
  };
  const afterMonth = () => {
    if (month === 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
    setOpen(0);
  };
  const beforeYear = () => {
    setYear(year - 1);
    setOpen(0);
  };
  const afterYear = () => {
    setYear(year + 1);
    setOpen(0);
  };
  const monthClick = (selectedMonth) => {
    setMonth(selectedMonth);
    setOpen(0);
  };
  const openMonthClick = (event) => {
    if (open === 2) {
      setOpen(0);
      return;
    }
    setOpen(open + 1);
    setAnchorElMonth(event.currentTarget);
  };
  const yearClick = (selectYear) => {
    setYear(selectYear);
    setOpen(0);
  };
  // 탭 전환
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <div style={{ height: 110 }}>
        <AlertMain />
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="진행" {...a11yProps(0)} />
            <Tab label="완료" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
        >
          <Button>
            <KeyboardDoubleArrowLeftIcon
              onClick={beforeYear}
              sx={{ cursor: "pointer", color: "black" }}
            />
          </Button>
          <Button>
            <KeyboardArrowLeftIcon
              onClick={beforeMonth}
              sx={{ cursor: "pointer", color: "black" }}
            />
          </Button>
          <Button
            aria-describedby="month-popper"
            onClick={openMonthClick}
            sx={{
              color: "black",
              fontSize: 20,
              fontWeight: "bold",
              margin: "0 8px",
            }}
          >
            {year + "년" + " " + month + "월"}
          </Button>
          <Button>
            <KeyboardArrowRightIcon
              onClick={afterMonth}
              sx={{ cursor: "pointer", color: "black" }}
            />
          </Button>
          <Button>
            <KeyboardDoubleArrowRightIcon
              onClick={afterYear}
              sx={{ cursor: "pointer", color: "black" }}
            />
          </Button>
        </Box>
        <Popper
          id="month-popper"
          anchorEl={anchorElMonth}
          open={open === 1 || open === 2}
          placement="bottom-start"
        >
          {open === 1 && (
            <GetMonth propsMonth={month} selectMonth={monthClick} />
          )}
          {open === 2 && <GetYear propsYear={year} selectYear={yearClick} />}
        </Popper>

        <CustomTabPanel value={value} index={0}>
          <PublicTable
            viewYearMonth={viewYearMonth}
            dataArray={masterAllList}
          />
          <PersonalTable
            viewYearMonth={viewYearMonth}
            dataArray={masterAllList}
            dptcdCombo={dptcdCombo}
          />
          {/* <Test viewYearMonth={viewYearMonth} /> */}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CplPublicTable
            viewYearMonth={viewYearMonth}
            tabValue={value}
            dataArray={masterAllList}
          />
          <CplPersonalTable
            tabValue={value}
            viewYearMonth={viewYearMonth}
            dataArray={masterAllList}
          />
        </CustomTabPanel>
      </Box>
    </div>
  );
};
export default Main;
