import React, { useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Paper } from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { loginSelect } from "../API/LoginAPI";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";
import { requestPermission } from "../firebase";
import Logo from "../image/AACT_logo.png";

const defaultTheme = createTheme();

function Login() {
  const { setErrFlag, setLoadFlag, setSocket } = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const apiUrl = process.env.REACT_APP_API_SOCKETURL;
  const navigator = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSocket(null);
    const form = event.target; // form 요소를 명시적으로 변수에 저장
    let token = sessionStorage.getItem("token");
    if (!token) {
      token = await requestPermission();
    }

    const formData = {
      username: form["username"].value,
      password: form["password"].value,
      userToken: token,
    };

    await loginSelect(formData, setErrFlag, setLoadFlag)
      .then(
        (result) =>
          result &&
          (navigator(sessionStorage.getItem("path")),
          setSocket(
            new WebSocket(
              `${apiUrl}/conSocket?token=${sessionStorage.getItem("jwt")}`
            )
          ))
      )
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
        <Container component="main">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Paper sx={{ pt: 5, pb: 3, pr: 10, pl: 10 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Login
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1, width: "25vw" }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="아이디"
                    name="username"
                    autoComplete="id"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="비밀번호"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      ),
                    }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    로그인
                  </Button>
                  <Grid container>
                    <Grid item>
                      <Link href="/Signup" variant="body2">
                        {"회원가입"}
                      </Link>
                    </Grid>
                  </Grid>
                  <img
                    src={Logo}
                    alt="logo"
                    style={{
                      width: "80px",
                      marginTop: 4,
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Login;
