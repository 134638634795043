import {
  Box,
  Modal,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import StarIcon from "@mui/icons-material/Star";
import React, { useEffect, useState } from "react";
import MainModal from "./MainModal";
import "./Table.css";
import moment from "moment";
import { setMasterTime } from "../API/MainAPI";
import CplRowModal from "../Chart/CplRowModal";
import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { AppContext } from "../AppContext";
import { useContext } from "react";
dayjs.extend(utc);
dayjs.extend(timezone);
function CplPersonalTable({ viewYearMonth, dataArray }) {
  const [personalData, setPersonalData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openRowModal, setOpenRowModal] = useState(false);
  const [editingRow, setEditingRow] = React.useState(null);
  const [editIndex, setEditIndex] = useState();
  const { setErrFlag, setLoadFlag, setMainFlag, monValue } =
    useContext(AppContext);
  const [value, setValue] = useState([]);
  useEffect(() => {
    const data = dataArray.filter(
      (item) => item.dataStatus === "P" && item.intraCompleteFlag === "Y"
    );
    setPersonalData(data);
  }, [dataArray]);
  useEffect(() => {
    if (personalData.length > 0) {
      const changeDate = personalData.map((item) => ({
        intraGuid: item.intraGuid,
        Start: item.intraStartTime,
        End: item.intraEndTime,
        StartFlag: false,
        EndFlag: false,
      }));
      setValue(changeDate);
    }
  }, [personalData]);

  const updateStartFlag = (index, type) => {
    setValue((prevChangeDate) => {
      const newChangeDate = [...prevChangeDate];
      newChangeDate[index] = {
        ...newChangeDate[index],
        [`${type}Flag`]: true,
      };
      return newChangeDate;
    });
  };
  const updateValue = (index, type, newValue) => {
    setValue((prevChangeDate) => {
      const newChangeDate = [...prevChangeDate];
      newChangeDate[index] = {
        ...newChangeDate[index],
        [`${type}`]: newValue,
      };
      return newChangeDate;
    });
  };

  const updateKeyValue = (event, index, type) => {
    if (event.key === "Enter") {
      setValue((prevChangeDate) => {
        const newChangeDate = [...prevChangeDate];
        newChangeDate[index] = {
          ...newChangeDate[index],
          [`${type}Flag`]: false,
        };
        return newChangeDate;
      });
    }
  };

  const cancelClick = () => {
    if (personalData.length > 0) {
      const changeDate = personalData.map((item) => ({
        intraGuid: item.intraGuid,
        Start: item.intraStartTime,
        End: item.intraEndTime,
        StartFlag: false,
        EndFlag: false,
      }));
      setValue(changeDate);
    }
  };

  useEffect(() => {
    // ESC 키를 눌렀을 때 호출될 핸들러 함수
    const handleEscKey = async (event) => {
      event.stopPropagation();
      if (event.key === "Escape") {
        cancelClick();
      } else if (event.key === "Enter") {
        handleSave();
      }
    };

    // 컴포넌트가 마운트될 때 이벤트 리스너 추가
    document.addEventListener("keydown", handleEscKey);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [value]);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRowClose = () => {
    setOpenRowModal(false);
    setEditIndex(undefined);
  };

  const selectRow = (index) => {
    setEditingRow(null);
    setValue((prevChangeDate) => {
      return prevChangeDate.map((item) => ({
        ...item,
        StartFlag: false,
        EndFlag: false,
      }));
    });
    const data = personalData[index].intraGuid;
    setEditIndex(data);
    setOpenRowModal(true);
  };
  const dataReFlagChange = () => {
    setMainFlag(true);
  };
  const handleSave = async () => {
    const data = value.map((item) => ({
      intraGuid: item.intraGuid,
      startTime: dayjs(item.Start).format("YYYY-MM-DD HH:mm:ss"),
      endTime: dayjs(item.End).format("YYYY-MM-DD HH:mm:ss"),
    }));

    await setMasterTime(data, setErrFlag, setLoadFlag).then(
      (result) => result && dataReFlagChange(true)
    );
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Paper sx={{ mt: 10, pr: 2, pl: 2, pb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
            mb: 2,
            ml: 2,
          }}
        >
          <Typography className="menuTitle">개인</Typography>
          <Box>
            <Button
              onClick={(e) => (e.stopPropagation(), handleSave())}
              size="small"
            >
              저장
            </Button>
            <Button
              onClick={(e) => (e.stopPropagation(), cancelClick())}
              size="small"
              sx={{ color: "red" }}
            >
              취소
            </Button>
          </Box>
        </Box>
        <TableContainer
          sx={{ borderRadius: 1, width: "90vw", maxHeight: "60vh" }}
        >
          <Table className="cpl-table">
            <TableHead sx={{ backgroundColor: "#e0ebd6" }}>
              <TableRow>
                <TableCell sx={{ width: "3%" }}>중요도</TableCell>
                <TableCell sx={{ width: "3%" }}>No</TableCell>
                <TableCell sx={{ width: "17%" }}>일정명</TableCell>
                <TableCell sx={{ width: "6%" }}>시작시간</TableCell>
                <TableCell sx={{ width: "6%" }}>종료시간</TableCell>
                <TableCell sx={{ width: "10%" }}>요청부서</TableCell>
                <TableCell sx={{ width: "17%" }}>세부일정명</TableCell>
                {Array.from(
                  { length: viewYearMonth },
                  (_, index) => index + 1
                ).map((day) => (
                  <TableCell key={day} sx={{ fontSize: 11, width: "1.2%" }}>
                    {day}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!(personalData.length === 0) &&
                personalData.map((row, index) => (
                  <TableRow
                    hover
                    key={index}
                    onClick={(event) => (
                      event.stopPropagation(),
                      personalData.length !== 0 && selectRow(index)
                    )}
                  >
                    <TableCell>
                      {row.intraImportantFlag === "Y" ? (
                        <StarIcon sx={{ color: "#FF7070" }}></StarIcon>
                      ) : null}
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.intraSubject}</TableCell>
                    <TableCell
                      onClick={(event) => {
                        event.stopPropagation();
                        updateStartFlag(index, "Start");
                      }}
                      sx={{
                        backgroundColor:
                          value[index] &&
                          dayjs(value[index].Start).format("MM-DD HH:mm") ===
                            dayjs(row.intraStartTime).format("MM-DD HH:mm")
                            ? ""
                            : "red",
                      }}
                    >
                      {value[index] && value[index].StartFlag ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimeField
                            size="small"
                            format="MM-DD HH:mm"
                            value={
                              value[index] &&
                              dayjs(value[index].Start || row.intraStartTime)
                            }
                            onChange={(newValue) =>
                              updateValue(index, "Start", newValue)
                            }
                            inputProps={{
                              style: { padding: 1, textAlign: "center" },
                            }}
                            onKeyDown={(event) => {
                              event.stopPropagation();
                              updateKeyValue(event, index, "Start");
                            }}
                          />
                        </LocalizationProvider>
                      ) : (
                        value[index] &&
                        dayjs(value[index].Start).format("MM-DD HH:mm")
                      )}
                    </TableCell>
                    <TableCell
                      onClick={(event) => {
                        event.stopPropagation();
                        updateStartFlag(index, "End");
                      }}
                      sx={{
                        backgroundColor:
                          value[index] &&
                          dayjs(value[index].End).format("MM-DD HH:mm") ===
                            dayjs(row.intraEndTime).format("MM-DD HH:mm")
                            ? ""
                            : "red",
                      }}
                    >
                      {value[index] && value[index].EndFlag ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimeField
                            size="small"
                            format="MM-DD HH:mm"
                            inputProps={{
                              style: { padding: 1, textAlign: "center" },
                            }}
                            value={
                              value[index] &&
                              dayjs(value[index].End || row.intraEndTime)
                            }
                            onChange={(newValue) =>
                              updateValue(index, "End", newValue)
                            }
                            onKeyDown={(event) => {
                              event.stopPropagation();
                              updateKeyValue(event, index, "End");
                            }}
                          />
                        </LocalizationProvider>
                      ) : (
                        value[index] &&
                        dayjs(value[index].End).format("MM-DD HH:mm")
                      )}
                    </TableCell>
                    <TableCell>{row.intraDeptStr}</TableCell>
                    <TableCell>
                      {row.dateData.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 0,
                            borderBottom: "none", // 필요에 따라 테이블 셀 경계를 조정
                          }}
                        >
                          <TableCell
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 0,
                              borderBottom: "none", // 필요에 따라 테이블 셀 경계를 조정
                              height: "25px", // 임시 고정 값
                            }}
                          >
                            {row.contents !== null ? row.contents : "\u00A0"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableCell>
                    {Array.from(
                      { length: viewYearMonth },
                      (_, index) => index + 1
                    ).map((day) => (
                      <TableCell sx={{ padding: 0 }} key={day}>
                        {row.dateData.map((item, itemIndex) => (
                          <TableRow
                            sx={{
                              padding: 0,
                              display: "contents",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            key={itemIndex}
                          >
                            <Box
                              sx={{
                                height: "23px",
                                mt: "2px",
                                backgroundColor: (() => {
                                  let color = "none";

                                  const itemSubArray = item.dateValue;
                                  if (itemSubArray) {
                                    for (
                                      let i = 0;
                                      i < itemSubArray.length;
                                      i++
                                    ) {
                                      if (
                                        itemSubArray[i].day === day &&
                                        monValue === itemSubArray[i].mon
                                      ) {
                                        switch (itemIndex % 5) {
                                          case 0:
                                            color = "#264653";
                                            break;
                                          case 1:
                                            color = "#2a9d8f";
                                            break;
                                          case 2:
                                            color = "#e9c46a";
                                            break;
                                          case 3:
                                            color = "#f4a261";
                                            break;
                                          case 4:
                                            color = "#e76f51";
                                            break;
                                          default:
                                            color = "none";
                                            break;
                                        }
                                        break;
                                      }
                                    }
                                  }
                                  return color;
                                })(),
                              }}
                            >
                              &nbsp;
                            </Box>
                          </TableRow>
                        ))}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <MainModal />
        </Box>
      </Modal>

      <Modal
        open={openRowModal}
        onClose={handleRowClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <CplRowModal intraGuid={editIndex} />
        </Box>
      </Modal>
    </Box>
  );
}

export default CplPersonalTable;
