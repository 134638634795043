import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Button, OutlinedInput, InputAdornment } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "../Main/Table.css";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import {
  setDetailAddRow,
  detailSave,
  detailRowDel,
  getDetailList,
} from "../API/ChartAPI";
import { getUserList } from "../API/MainAPI";
import { getINTRACombo } from "../API/AlarmAPI";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import TableCombo from "../Util/TableCombo";
import dayjs from "dayjs";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: 3,
  boxShadow: 24,
  pt: 4,
  pr: 4,
  pl: 4,
  pb: 2,
};

export default function BasicTable({ intraGuid }) {
  const [rows, setRows] = React.useState([]);
  const [detailClicked, setDetailClicked] = React.useState({});
  const [intraCombo, setIntraCombo] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [editingRow, setEditingRow] = React.useState(null);
  const [editedValues, setEditedValues] = React.useState({});
  const comboTableRef = React.useRef(null);
  const { setErrFlag, setLoadFlag, chartFlag, setChartFlag } =
    useContext(AppContext);
  React.useEffect(() => {
    getINTRACombo()
      .then((result) => result && setIntraCombo(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    getUserList()
      .then((result) => result && setUserList(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  React.useEffect(() => {
    if (intraGuid) {
      getDetailList(intraGuid, setErrFlag, setLoadFlag)
        .then((result) => {
          if (result === false) {
            setRows([]);
          } else {
            setRows(result);
          }
        })
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
      setChartFlag(false);
    }
  }, [intraGuid, chartFlag]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        comboTableRef.current &&
        !comboTableRef.current.contains(event.target)
      ) {
        setDetailClicked({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeClick = () => {
    setEditingRow(null);
    setChartFlag(true);
  };

  const handleEdit = (index) => {
    setEditingRow(index);
    setEditedValues({
      ...editedValues,
      [index]: {
        detailGuid: rows[index].detailGuid,
        intraGuid: rows[index].intraGuid,
        detailContents: rows[index].detailContents,
        detailRemark: rows[index].detailRemark,
        detailStartTIme: rows[index].detailStartTIme,
        detailEndTime: rows[index].detailEndTime,
        detailTag: rows[index].detailTag,
      },
    });
  };

  const handleDelete = (index) => {
    detailRowDel(rows[index].detailGuid, setErrFlag, setLoadFlag)
      .then((result) => result && closeClick())
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const handleSave = (index) => {
    const editedRow = editedValues[index];

    const retData = [
      {
        detailGuid: editedRow.detailGuid,
        intraGuid: editedRow.intraGuid,
        detailContents: editedRow.detailContents,
        detailStartTIme: editedRow.detailStartTIme,
        detailEndTime: editedRow.detailEndTime,
        detailRemark:
          editedRow.detailRemark === null
            ? null
            : String(editedRow.detailRemark).replace("@", ""),
        sendUserSid: sessionStorage.getItem("userSid"),
        userSid: editedRow.detailTag === "" ? null : editedRow.detailTag,
      },
    ];
    detailSave(retData, setErrFlag, setLoadFlag)
      .then((result) => result && closeClick())
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const handleEditInputChange = (index, field, value) => {
    if (field === "detailTag") {
      setEditedValues({
        ...editedValues,
        [index]: {
          ...editedValues[index],
          [field]: value.map((item) => item.userSid),
        },
      });
    } else {
      setEditedValues({
        ...editedValues,
        [index]: {
          ...editedValues[index],
          [field]: value,
        },
      });
    }

    if (field === "detailContents") {
      setDetailClicked({});
    }
  };

  const addRowClick = () => {
    const userGuid = sessionStorage.getItem("userSid");
    setDetailAddRow(intraGuid, userGuid, setErrFlag, setLoadFlag)
      .then((result) => result && closeClick())
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const detailTableOpen = (index) => {
    setDetailClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <Box sx={style}>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "70vh", overflowX: "auto" }}
      >
        <Table sx={{ minWidth: 650 }} className="basicTable">
          <TableHead sx={{ backgroundColor: "#cfe0c3" }}>
            <TableRow>
              <TableCell sx={{ width: 50 }}>No</TableCell>
              <TableCell sx={{ width: 300 }}>내용</TableCell>
              <TableCell sx={{ width: 300 }}>시작시간</TableCell>
              <TableCell sx={{ width: 300 }}>종료시간</TableCell>
              <TableCell sx={{ width: 200 }}>Remark</TableCell>
              <TableCell sx={{ width: 100 }}>완료</TableCell>
              <TableCell sx={{ width: 150 }}>편집</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.detailContents}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="center">
                  {editingRow === index ? (
                    <Box
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      <OutlinedInput
                        size="small"
                        className="admin-input"
                        variant="outlined"
                        value={
                          editedValues[index]?.detailContents !== undefined
                            ? editedValues[index].detailContents
                            : row.detailContents
                        }
                        onClick={(event) => {
                          detailTableOpen(index);
                          event.stopPropagation();
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(event) => {
                                detailTableOpen(index);
                                event.stopPropagation();
                              }}
                            >
                              {detailClicked[index] ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <Box ref={detailClicked[index] ? comboTableRef : null}>
                        {detailClicked[index] && (
                          <TableCombo
                            tableList={intraCombo}
                            changeData={(value) =>
                              handleEditInputChange(
                                index,
                                "detailContents",
                                value
                              )
                            }
                            type={"DTAIL"}
                            width={"17%"}
                          />
                        )}
                      </Box>
                    </Box>
                  ) : (
                    row.detailContents
                  )}
                </TableCell>
                <TableCell>
                  {editingRow === index ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimeField
                        size="small"
                        format="MM-DD HH:mm"
                        value={
                          editedValues[index]?.detailStartTIme
                            ? dayjs(editedValues[index].detailStartTIme)
                            : dayjs(row.detailStartTIme)
                        }
                        onChange={(newValue) => {
                          handleEditInputChange(
                            index,
                            "detailStartTIme",
                            newValue
                          );
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </LocalizationProvider>
                  ) : (
                    moment(row.detailStartTIme).format("MM-DD HH:mm")
                  )}
                </TableCell>
                <TableCell>
                  {editingRow === index ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimeField
                        size="small"
                        format="MM-DD HH:mm"
                        value={
                          editedValues[index]?.detailEndTime
                            ? dayjs(editedValues[index].detailEndTime)
                            : null
                        }
                        onChange={(newValue) => {
                          handleEditInputChange(
                            index,
                            "detailEndTime",
                            newValue
                          );
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </LocalizationProvider>
                  ) : row.detailEndTime !== null ? (
                    moment(row.detailEndTime).format("MM-DD HH:mm")
                  ) : null}
                </TableCell>
                <TableCell align="center">
                  {editingRow === index ? (
                    <TextField
                      size="small"
                      variant="standard"
                      label="Remark"
                      value={editedValues[index]?.detailRemark || ""}
                      onChange={(e) =>
                        handleEditInputChange(
                          index,
                          "detailRemark",
                          e.target.value
                        )
                      }
                    />
                  ) : (
                    row.detailRemark
                  )}
                </TableCell>
                <TableCell align="center">
                  {editingRow === index ? (
                    <Checkbox
                      id={`detailEndTime-${index}`}
                      checked={editedValues[index]?.detailEndTime}
                      onClick={(e) =>
                        handleEditInputChange(
                          index,
                          "detailEndTime",
                          e.target.checked === true ? dayjs(new Date()) : null
                        )
                      }
                    />
                  ) : (
                    <Checkbox checked={row.detailEndTime} disabled />
                  )}
                </TableCell>
                <TableCell align="center">
                  {editingRow === index ? (
                    <IconButton
                      aria-label="save"
                      onClick={() => handleSave(index)}
                    >
                      <CheckIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  ) : (
                    <React.Fragment>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(index)}
                      >
                        <EditIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(index)}
                      >
                        <DeleteIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </React.Fragment>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ textAlign: "right", marginTop: "1%" }}>
        <IconButton onClick={addRowClick}>
          <AddCircleIcon sx={{ fontSize: 45, color: "#4A7E4F" }} />
        </IconButton>
      </Box>
    </Box>
  );
}
