import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUserListDropDown, getDPTCDDropDown } from "../API/MainAPI";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import TableComboSec from "../Util/TableComboSec";
import { getAlarmMasterUserList, setAlarmMasterSave } from "../API/AlarmAPI";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const MainModal = ({ intraGuid, handleClose }) => {
  const [userList, setUserList] = useState([]);
  const [tagValue, setTagValue] = useState([]);
  const [dptTag, setdptTag] = useState([]);
  const [dtoData, setDtoData] = useState([]);
  const [dptList, setDptList] = useState([]);
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  useEffect(() => {
    getUserListDropDown()
      .then((result) => result && setUserList(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    getDPTCDDropDown()
      .then((result) => result && setDptList(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  useEffect(() => {
    const userSid = sessionStorage.getItem("userSid");

    if (intraGuid.length > 0) {
      getAlarmMasterUserList(userSid, intraGuid, setErrFlag, setLoadFlag)
        .then((result) => result && setDtoData(result))
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  }, [intraGuid]);

  useEffect(() => {
    if (dtoData.length > 0) {
      const deptList = dtoData
        .filter((item) => item.type === "DEPT")
        .map((item) => ({ guid: item.guid }));
      const userList = dtoData
        .filter((item) => item.type === "USER")
        .map((item) => ({ guid: item.guid }));
      setdptTag(deptList);
      setTagValue(userList);
    }
  }, [dtoData]);

  const saveClick = async () => {
    const saveData = {
      intraSid: intraGuid,
      detailSid: null,
      sendUserSid: sessionStorage.getItem("userSid"),
      receiveUserSid: tagValue.map((item) => item.guid),
      deptList: dptTag.map((item) => item.guid),
    };
    setAlarmMasterSave(saveData, setErrFlag, setLoadFlag)
      .then((result) => result && handleClose())
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const pushValue = (guid) => {
    setdptTag([...dptTag, guid]);
  };

  const pushUser = (guid) => {
    setTagValue([...tagValue, guid]);
  };
  const deleteDept = (data) => {
    setdptTag(data);
  };
  const deleteUser = (data) => {
    setTagValue(data);
  };

  return (
    <Box sx={style}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
          mb: 3,
          ml: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", fontFamily: "Nanum Gothic" }}
        >
          알림설정
        </Typography>
        <Box sx={{ textAlign: "right" }}>
          <Button onClick={saveClick}>저장</Button>
        </Box>
      </Box>
      <Box sx={{ pl: 2 }}>
        <Typography sx={{ fontFamily: "Noto Sans KR", fontSize: 15 }}>
          부서
        </Typography>
        <TableComboSec
          tableList={dptList && dptList}
          type={"DEPT"}
          value={dptTag}
          changeData={pushValue}
          deleteData={deleteDept}
          showValue={"value2"}
          width={"75%"}
        />
        <Typography sx={{ fontFamily: "Noto Sans KR", fontSize: 15, mt: 3 }}>
          개인
        </Typography>
        <TableComboSec
          tableList={userList && userList}
          type={"USER"}
          value={tagValue}
          changeData={pushUser}
          deleteData={deleteUser}
          showValue={"value3"}
          width={"75%"}
        />
      </Box>
    </Box>
  );
};

export default MainModal;
